<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  }
})

const { id, className } = props.data.props
const { children } = props.data
</script>
<template>
  <div
    :id="id"
    :class="className"
  >
    <TheAdComponent
      v-for="item in children"
      :key="item.id"
      :data="item"
    />
  </div>

</template>